<template>
  <div class="activity-container">
    <div class="activity-content">
      <div class="activity-switch-type">
        <div class="activity-switch-type-title"></div>
        <div class="activity-switch-type-content">
          <div
            class="activity-switch-type-content-li"
            :class="{
              'activity-switch-type-content-li-active': current == index,'imgType':item.name.length > 4
            }"
            v-for="(item, index) in categoryList"
            :key="item.id"
            @click="handleChangeValue(index, item.id)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="activity-switch-type">
        <div class="activity-switch-type-title"></div>
        <div class="activity-switch-type-content">
          <div
            class="activity-switch-type-content-li"
            :class="{
              'activity-switch-type-content-li-active': current1 == index,'imgType':item.name.length > 4
            }"
            v-for="(item, index) in typeList"
            :key="item.id"
            @click="handleChangeStatus(index, item.id)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <!-- 最新·最热 -->
      <div class="activity-content-hot-new">
        <div class="activity-content-hot-new-icon">
          <img
            style="width: 100%; height: 100%"
            src="https://image.bookgo.com.cn/webculture/jm/title_left_icon.png"
            alt=""
          />
        </div>
        <div
          class="activity-content-hot-new-title"
          @click="handleChangeValue2('active_start_time')"
        >
          <div
            :style="{
              'font-weight': value2 == 'active_start_time' ? 'bold' : '',
            }"
          >
            最新
          </div>
          <div
            class="activity-content-hot-new-title-bg"
            :style="{
              display: value2 == 'active_start_time' ? 'block' : 'none',
            }"
          ></div>
        </div>
        <div class="activity-content-new-mid-hot"></div>
        <div
          class="activity-content-hot-new-title activity-content-hot-new-title1"
          @click="handleChangeValue2('view_count')"
        >
          <div :style="{ 'font-weight': value2 == 'view_count' ? 'bold' : '' }">
            最热
          </div>
          <div
            class="activity-content-hot-new-title-bg"
            :style="{ display: value2 == 'view_count' ? 'block' : 'none' }"
          ></div>
        </div>
        <div class="activity-content-hot-new-icon">
          <img
            style="width: 100%; height: 100%"
            src="https://image.bookgo.com.cn/webculture/jm/title_right_icon.png"
            alt=""
          />
        </div>
      </div>

      <div v-if="total > 0">
        <listItem
          listType="activity"
          :list="content"
          v-loading="listLoading"
        ></listItem>
        <!-- 分页组件 -->
        <pagination
          :total="total"
          :page.sync="page"
          :limit.sync="limit"
          @pagination="getActivityList"
        />
      </div>
      <!-- 没有内容显示的页面 -->
      <Nocontent
        title="暂无群文活动，请持续关注哦～"
        v-if="total == 0"
      ></Nocontent>
    </div>
  </div>
</template>

<script>
import { getCateList, getActivityList } from "@/api/activity/activity";
import Pagination from "@/components/Pagination";
import ListItem from "@/views/components/listItem";
import Nocontent from "@/views/components/Nocontent";
export default {
  data() {
    return {
      listLoading: true,
      total: 0,
      page: 1,
      limit: 9,
      aid: process.env.VUE_APP_AID,
      current: 0,
      current1: 0,
      id:this.$route.query.id,
      cur: 0,
      //活动状态，-1全部，1已结束，2报名中，3报名已结束活动未开始，4进行中，5报名未开始
      typeList: [
        { id: -1, name: "全部" },
        { id: 6, name: "待开始" },
        { id: 2, name: "进行中" },
        { id: 1, name: "已结束" },
      ],
      subTypeList: ["默认", "最新", "最热"],

      content: [],
      categoryList: [],
      option2: [
        { value: "active_start_time", label: "最新上线" },
        { value: "view_count", label: "人气最高" },
      ],
      value1: "全部",
      value2: "active_start_time",
      status: "",
    };
  },
  components: {
    Pagination,
    ListItem,
    Nocontent,
  },
  mounted() {
    window.scroll(0, 0);
    this.getCategory().then((res) => {
      let data = res.data;
      data.unshift({ id: 0, name: "全部" });
      this.categoryList = data;
      console.info(this.categoryList);
      this.getActivityList();
    });
  },
  methods: {
    getCategory() {
      return getCateList({ aid: this.aid ,id:41});
    },
    handleChangeStatus(i, status) {
      this.page = 1;
      this.current1 = i;
      this.status = status;

      this.getActivityList(status);
    },
    handleChangeValue(i, value1) {
      this.page = 1;
      this.current = i;
      this.value1 = value1;
      this.getActivityList(this.status);
    },

    handleChangeValue2(value2) {
      this.page = 1;
      this.value2 = value2;
      this.getActivityList(this.status);
    },

    getActivityList() {

      this.typeList.forEach((item) => {
        item.id;
      });
      let params = {
        aid: this.aid,
        cat1:this.id,
        page: this.page,
        page_size: this.limit,
        status:this.status,
      };

      if (this.value2 != "") {
        params.sort_field = this.value2;
      }
      console.info(this.value2);
      if (parseInt(this.value1) > 0) {
        params.cat2 = parseInt(this.value1);
      }
      console.log(params)
      getActivityList(params).then((res) => {
        // this.listLoading = true;
        if (res.code == 0) {
          this.content = res.data.datalist;
          this.total = res.data.total;
        }
      });
      this.listLoading = false;
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/activity.scss";
</style>
